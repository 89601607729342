import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import { CCol, CModalBody, CModalHeader, CRow } from '@coreui/react-pro'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import axios from '../../../shared/config/axios-interceptor';
import { RootState } from '@/reducers'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import SModal from '@/components/shared/Modal/SModal'
import bank from '../../../assets/img/web/bank.jpeg'

const LichSuDat = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication);
  const [history, setHistory] = useState([]);


    const getData = async () => {
        try {
        const result = await axios.get('/history?userId=' + user?.id);
        setHistory(result.data.data);
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [user])
 
  const [detail, setDetail ] = useState<any>(null);

  const [showThanhToan, setShowThanhToan] = useState<any>(false);



  const [bank, setBank] = useState<any>(null)
  const getData1 = async () => {
    try {
        const result = await axios.get('/configs/1');
        setBank(result.data);
    }catch(error){
        console.log(error)
    }   
  }

  useEffect( () => {
    getData1()
  }, [])


  return (
    <Layout>
        <div className='py-5' style={{ backgroundColor: '#f7f7f7' }}>

            <div className='container-custom'>

            <div className="p-4" style={{ background: "#fff" }}>
                <h3>Lịch sử đặt vé</h3>
                
                <div className="mt-3 pb-5">
                    {
                        history.map( (item: any, index) => {
                            return (
                                <div key={index} className="border-bottom mt-3 py-2 item-dat-ve" id={item?.id}>
                                    <div className="mb-3" style={{ fontSize: 18 }}>{index + 1}.Thời gian đặt: <strong>{dayjs(item?.createdDate).format('HH:mm DD/MM/YYYY')}</strong> 
                                    { item?.status == 'Chưa thanh toán' ? <span onClick={() => setShowThanhToan(true)} style={{ color: "rgb(44, 172, 226)", cursor: "pointer"}}>  (Thanh toán tại đây <span className={`total${item.id}`}></span>)</span> : '' }
                                    </div>
                                    <CRow className="align-items-center g-2" key={index}>
                                    <CCol md={3}>
                                        <div onClick={() => setDetail(item)} className="fw-bold cursor-pointer" style={{ fontSize: 18, color: "rgb(44, 172, 226)" }}>Xem chi tiết</div>
                                        <div className="mt-1">Họ và tên: <strong>{item?.hoVaTen}</strong></div>
                                        <div className="mt-1">Giới tính: <strong>{item?.gioiTinh}</strong></div>
                                        <div className="mt-1">Ngày sinh: <strong>{item?.ngaySinh}</strong></div>
                                        <div className="mt-1">Số điện thoại: <strong>{item?.soDienThoai}</strong></div>
                                    </CCol>

                                    <CCol md={3}>
                                        <div>Số CCCD: <strong>{item?.cccd}</strong></div>
                                        <div className="mt-1">Quốc tịch: <strong>{item?.quocTich}</strong></div>
                                        <div className="mt-1">Nơi cấp: <strong>{item?.noiCap}</strong></div>
                                        <div className="mt-1">Ngày hết hạn: <strong>{item?.ngayHetHan}</strong></div>
                                    </CCol>

                                    <CCol md={3}>
                                        <div className="noi-dung-admin mb-0">
                                            <div dangerouslySetInnerHTML={{ __html: item.noiDung }}
                                            ></div>  
                                        </div>
                                    </CCol>

                                    <CCol md={3}>
                                        <div className="mt-1">Trạng thái: <strong className={item?.status == 'Chưa thanh toán' ? 'text-danger' : 'text-success'}>{item?.status}</strong></div>
                                        <div className="mt-1" style={{ fontSize: 18, color: "#fc7700" }}>Phiếu giảm giá ABAY: <strong>{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND', maximumFractionDigits: 0}).format(630000) }</strong></div>
                                        <div className="mt-1">Tổng tiền cần thanh toán: <strong className={`total${item.id} ` + (item?.status == 'Chưa thanh toán' ? 'text-danger' : 'text-success')}></strong></div>
                                    </CCol>
                                </CRow>
                                </div>
                            )
                        }) 
                    }
                </div>
            </div>

            </div>

        </div>
      <Ads />
      <DoiTac />



      <SModal visible={showThanhToan} size={'xl'} onClose={() => setShowThanhToan(false)} backdrop="static" className="custom-modal">
        <CModalHeader>
          <h5>Thông tin thanh toán</h5>
        </CModalHeader>
        <CModalBody>
            <div>
                <h4 className="mt-0">Hướng dẫn thanh toán</h4>
                <p className="border-0">Quý khách vui lòng chuyển khoản đúng <strong>SỐ TIỀN</strong> và <strong>NỘI DUNG</strong> trong email để tránh sai sót. Sau khi chuyển khoản xong, quý khách vui lòng thông báo lại cho <strong>Vé máy bay ABAY</strong> qua số các nhân viên booker để chúng tôi xuất vé cho quý khách. </p>
                <div className="py-3 text-center">
                    <img style={{maxWidth: 300}} src={`https://img.vietqr.io/image/${bank?.content1}-${bank?.content2}-compact.jpg`} />
                </div>
                <p className="border-0">Ngân hàng: <strong>{bank?.content1}</strong></p>
                <p className="border-0">Chủ tài khoản: <strong>{bank?.content2}</strong></p>
                <p className="border-0">Số tài khoản: <strong>{bank?.content3}</strong></p>
            </div>
        </CModalBody>
      </SModal>
      

      <SModal visible={detail ? true : false} size={'xl'} onClose={() => setDetail(null)} backdrop="static" className="custom-modal">
        <CModalHeader>
          <h5>Thông tin chi tiết</h5>
        </CModalHeader>
        <CModalBody>
            <div className="noi-dung-chi-tiet-admin">
              <div dangerouslySetInnerHTML={{ __html: detail?.noiDungChiTiet ?? '' }}></div>  
            </div>
        </CModalBody>
      </SModal>
      
    </Layout>
  )
}

export default LichSuDat
