import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'

const OrderCheck = () => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Layout>

        <div className="py-5 page-order-check">
            <div className="container-custom py-5">
                <h2 className="text-center">Kiểm tra đơn hàng</h2>

                <div style={{ fontSize: 14, maxWidth: 500, margin: '0 auto' }}>
                    
                    <div>
                        <CFormLabel className="text-black" style={{ fontSize: 16 }}>Mã đơn hàng</CFormLabel>
                        <CFormInput type="text" placeholder="Nhập mã đơn hàng" style={{ backgroundColor: '#edf2f7' }}/>
                    </div>

                    <div className="mt-3"  style={{ fontSize: 16 }}>
                        <CFormLabel className="text-black">Số điện thoại</CFormLabel>
                        <CFormInput type="text" placeholder="Nhập số điện thoại" style={{ backgroundColor: '#edf2f7' }}/>
                    </div>

                    <div className="mt-3 text-end">
                        <CButton onClick={() => window.location.reload()} style={{ border: "1px solid #ff4001", backgroundColor: "transparent", color: "#ff4001" }}>
                            <i className="fa-solid fa-magnifying-glass me-1"></i>
                            Kiểm tra
                        </CButton>
                    </div>
                </div>
            </div>
        </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default OrderCheck
