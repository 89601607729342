import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import { useEffect, useState } from 'react'
import DataComPlugin from './components/DataComPlugin'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import DataComSearchPlugin from './components/DataComSearchPlugin'
import { RootState } from '@/reducers'
import axios from '../../../shared/config/axios-interceptor';

const Search = () => {
  const { location } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication);
  console.log(location.search);
  const dispatch = useDispatch<AppDispatch>()
  const [a,setA] = useState(false);

  // useEffect(() => {
  //   // window.location.reload()
  //   setA(!a)
  // }, [a])

  const [bank, setBank] = useState<any>(null)
  const getData1 = async () => {
    try {
        const result = await axios.get('/configs/1');
        setBank(result.data);
    }catch(error){
        console.log(error)
    }   
  }

  useEffect( () => {
    getData1()
  }, [])

  return (
    <Layout>
      <div className="container-custom py-5">
        <DataComSearchPlugin />
        <DataComPlugin />

        <div className="form-dat-ve">

          <form id="form-dat-ve">
            <input name="userId"
                type="hidden" value={user?.id}
              />
            <div className="dtc-fare-wraper" id="dtc-pax-info1">
                <div className="dtc-title dtc-color-border dtc-title1">
                  Đặt chỗ
                </div>

                <h6 className="mt-3 px-3">Thông tin hành khách</h6>
                <div className="dtc-box-option">
                  {" "}
                  <ul className="dtc-pax-info">
                    <li style={{ padding: 0, margin: 0}}>
                    </li>
                    <li value={1}>
                      {" "}
                      <div className="dtc-pax-row">
                        {" "}
                        <div className="dtc-pax-customer dtc-pax-sex">
                          <div className="dtc-input-group">
                            <select name="gioiTinh" className="dtc-input dtc-input-gender" >
                              <option value="Nam">Nam</option>
                              <option value="Nữ">Nữ</option>
                            </select>
                          </div>
                        </div>{" "}
                        <div className="dtc-pax-customer dtc-pax-firstname">
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <input
                              className="dtc-input dtc-req dtc-input-firstname"
                              name="hoVaTen"
                              type="text"
                              maxLength={160}
                              placeholder="Nhập họ và tên"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="dtc-pax-customer dtc-pax-bir">
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <span className="dtc-input-icon">
                              <i className="fa fa-calendar" aria-hidden="true" />
                            </span>{" "}
                            <input
                              name="ngaySinh"
                              className="dtc-input dtc-picker-adt"
                              placeholder="dd-MM-yyyy"
                              type="datetime"
                              autoComplete="off"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}

                        <div className="dtc-pax-customer dtc-pax-firstname">
                          <div className="dtc-input-group">
                            <input
                              name="soDienThoai"
                              className="dtc-input dtc-req dtc-input-firstname"
                              type="text"
                              maxLength={160}
                              placeholder="Số điện thoại"
                              required
                            />
                          </div>
                        </div>

                        <div className="dtc-pax-customer dtc-pax-firstname">
                          <div className="dtc-input-group">
                            <input
                              name="diaChi"
                              className="dtc-input dtc-req dtc-input-firstname"
                              type="text"
                              maxLength={160}
                              placeholder="Địa chỉ"
                              required
                            />
                          </div>
                        </div>

                      </div>
                      <div className="dtc-pax-row">
                        {" "}
                        <div
                          className="dtc-pax-customer dtc-pax-firstname"
                          style={{ width: "30%" }}
                        >
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <input
                              name="cccd"
                              className="dtc-input dtc-req dtc-input-passport"
                              type="text"
                              maxLength={160}
                              placeholder="CCCD/ Hộ chiếu"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          className="dtc-pax-customer dtc-pax-firstname"
                          style={{ width: "20%" }}
                        >
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <input
                              name="quocTich"
                              className="dtc-input dtc-input-nationality"
                              type="text"
                              maxLength={160}
                              placeholder="Quốc tịch (VD: VNM)"
                              defaultValue="VNM"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          className="dtc-pax-customer dtc-pax-firstname"
                          style={{ width: "20%" }}
                        >
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <input
                              name="noiCap"
                              className="dtc-input dtc-input-issuecountry"
                              type="text"
                              maxLength={160}
                              placeholder="Nơi cấp (VD: VNM)"
                              defaultValue="VNM"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          className="dtc-pax-customer dtc-pax-firstname"
                          style={{ width: "30%" }}
                        >
                          {" "}
                          <div className="dtc-input-group">
                            {" "}
                            <input
                              name="ngayHetHan"
                              className="dtc-input dtc-input-expirydate"
                              type="text"
                              maxLength={160}
                              placeholder="Ngày hết hạn (VD: 31122030)"
                              required
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </li>{" "}
                  </ul>{" "}

                  <div className="d-flex justify-content-center">
                    <button id="form-dat-ve1" type="button" style={{ background: "linear-gradient(96deg,#2e3490,#448fd3)", border: "none", outline: "none", color: "#fff", padding: "15px 70px", borderRadius: 5, marginTop: 20, marginBottom: 20 }}>ĐẶT VÉ</button>
                  </div>

                  <h4 className="mt-4">Hướng dẫn thanh toán</h4>
                  <p className="border-0">Quý khách vui lòng chuyển khoản đúng <strong>SỐ TIỀN</strong> và <strong>NỘI DUNG</strong> trong email để tránh sai sót. Sau khi chuyển khoản xong, quý khách vui lòng thông báo lại cho <strong>Vé máy bay ABAY</strong> qua số các nhân viên booker để chúng tôi xuất vé cho quý khách. </p>
                  <div className="py-3 text-center">
                    <img style={{maxWidth: 300}} src={`https://img.vietqr.io/image/${bank?.content1}-${bank?.content2}-compact.jpg`} />
                  </div>
                  <p className="border-0">Ngân hàng: <strong>{bank?.content1}</strong></p>
                  <p className="border-0">Chủ tài khoản: <strong>{bank?.content2}</strong></p>
                  <p className="border-0">Số tài khoản: <strong>{bank?.content3}</strong></p>
            
                  <div className="dtc-pax-note">
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-triangle" aria-hidden="true" /> Lưu ý về
                      độ tuổi người lớn, trẻ em, trẻ sơ sinh
                    </span>{" "}
                    <ul>
                      <li>
                        <i className="fa fa-hand-o-right" aria-hidden="true" /> Người lớn từ
                        12 tuổi trở lên
                      </li>{" "}
                      <li>
                        <i className="fa fa-hand-o-right" aria-hidden="true" /> Nếu trẻ em và
                        em bé của bạn không sinh vào khoảng thời gian trên, vui lòng chọn ngày
                        bay khác
                      </li>{" "}
                    </ul>{" "}
                    <span>
                      <i className="fa fa-exclamation-triangle" aria-hidden="true" /> Lưu ý:
                      thông tin quốc tịch và nơi cấp quý khách vui lòng nhập dưới dạng mã quốc
                      gia 3 kí tự, ví dụ: VNM, USA, ...
                    </span>{" "}
                  </div>
                </div>
              </div>
              
          </form>

        </div>
      </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default Search
