import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import Edit02Icon from '@/components/shared/icons/Edit02Icon';
import { ToastSuccess } from '@/components/shared/toast/Toast';
import {
  CAvatar,
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CModalHeader,
  CRow,
  CSmartTable,
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter';
import { createEntity, getEntities, getEntity, removeEntity, updateEntity } from './configsManagement.api';
import { fetching, resetEntity, selectEntityById, setFilterState, veSelectors } from './configsManagement.reducer';
import { IVe } from '@/shared/model/ve.model';
import { Formik } from 'formik';
import { IConfigs, INewConfigs } from '@/shared/model/configs.models';
import * as Yup from 'yup';
import Select, { SingleValue, components } from 'react-select'
import { ISelectValue } from '@/shared/shared-interfaces';


const ConfigsManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.configsReducer);
  const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
  const [createUser, setCreateUser] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<IVe | null>(null);

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
  }, [updateEntitySuccess]);

  useEffect(() => {
    dispatch(getEntity(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useSelector(selectEntityById(1))

  const initialValues: INewConfigs = {
    content1: ''
  };
  
  const validationSchema = Yup.object().shape({
    content1: Yup.string().required('Không được để trống'),
  });

  const returnOption = (
    optionValue: string | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => value == optionValue)
  }

  const banks = [
    { value: "VietinBank", label: "VietinBank" },
    { value: "Vietcombank", label: "Vietcombank" },
    { value: "BIDV", label: "BIDV" },
    { value: "Agribank", label: "Agribank" },
    { value: "OCB", label: "OCB" },
    { value: "MBBank", label: "MBBank" },
    { value: "Techcombank", label: "Techcombank" },
    { value: "ACB", label: "ACB" },
    { value: "VPBank", label: "VPBank" },
    { value: "TPBank", label: "TPBank" },
    { value: "Sacombank", label: "Sacombank" },
    { value: "HDBank", label: "HDBank" },
    { value: "VietCapitalBank", label: "VietCapitalBank" },
    { value: "SCB", label: "SCB" },
    { value: "VIB", label: "VIB" },
    { value: "SHB", label: "SHB" },
    { value: "Eximbank", label: "Eximbank" },
    { value: "MSB", label: "MSB" },
    { value: "CAKE", label: "CAKE" },
    { value: "Ubank", label: "Ubank" },
    { value: "Timo", label: "Timo" },
    { value: "ViettelMoney", label: "ViettelMoney" },
    { value: "VNPTMoney", label: "VNPTMoney" },
    { value: "SaigonBank", label: "SaigonBank" },
    { value: "BacABank", label: "BacABank" },
    { value: "PVcomBank", label: "PVcomBank" },
    { value: "Oceanbank", label: "Oceanbank" },
    { value: "NCB", label: "NCB" },
    { value: "ShinhanBank", label: "ShinhanBank" },
    { value: "ABBANK", label: "ABBANK" },
    { value: "VietABank", label: "VietABank" },
    { value: "NamABank", label: "NamABank" },
    { value: "PGBank", label: "PGBank" },
    { value: "VietBank", label: "VietBank" },
    { value: "BaoVietBank", label: "BaoVietBank" },
    { value: "SeABank", label: "SeABank" },
    { value: "COOPBANK", label: "COOPBANK" },
    { value: "LPBank", label: "LPBank" },
    { value: "KienLongBank", label: "KienLongBank" },
    { value: "KBank", label: "KBank" },
    { value: "KookminHN", label: "KookminHN" },
    { value: "KEBHanaHCM", label: "KEBHanaHCM" },
    { value: "KEBHanaHN", label: "KEBHanaHN" },
    { value: "MAFC", label: "MAFC" },
    { value: "Citibank", label: "Citibank" },
    { value: "KookminHCM", label: "KookminHCM" },
    { value: "VBSP", label: "VBSP" },
    { value: "Woori", label: "Woori" },
    { value: "VRB", label: "VRB" },
    { value: "UnitedOverseas", label: "UnitedOverseas" },
    { value: "StandardChartered", label: "StandardChartered" },
    { value: "PublicBank", label: "PublicBank" },
    { value: "Nonghyup", label: "Nonghyup" },
    { value: "IndovinaBank", label: "IndovinaBank" },
    { value: "IBKHCM", label: "IBKHCM" },
    { value: "IBKHN", label: "IBKHN" },
    { value: "HSBC", label: "HSBC" },
    { value: "HongLeong", label: "HongLeong" },
    { value: "GPBank", label: "GPBank" },
    { value: "DongABank", label: "DongABank" },
    { value: "DBSBank", label: "DBSBank" },
    { value: "CIMB", label: "CIMB" },
    { value: "CBBank", label: "CBBank" }
];


  const customBankItems: any = banks

  if(!data) return <></>

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-content">
      <Formik
        initialValues={data || initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching());
          if (data) {
            dispatch(updateEntity(value as IConfigs));
          } else {
            dispatch(createEntity(value));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
                <CRow className="g-3">
                <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên ngân hàng
                    </CFormLabel>

                    <Select
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.content1 as any,
                          customBankItems
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>
                      ) => {
                        setFieldValue(`content1`, newValue?.value || "");
                      }}
                      noOptionsMessage={() => <>Không có ngân hàng nào</>}
                      id={"content1"}
                      options={customBankItems}
                      placeholder={"Chọn Ngân Hàng"}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={"absolute"}
                      name="content1"
                    />

                    <CFormFeedback
                      invalid
                      className={
                        !!errors.content1 && touched.content1
                          ? "d-block"
                          : "d-none"
                      }
                    >
                      {errors.content1}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Số tài khoản</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="content2"
                      autoComplete="off"
                      value={values.content2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.content2 && touched.content2}
                    />
                    <CFormFeedback invalid className={!!errors.content2 && touched.content2 ? 'd-block' : 'd-none'}>
                      {errors.content2}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Chủ tài khoản</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="content3"
                      autoComplete="off"
                      value={values.content3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.content3 && touched.content3}
                    />
                    <CFormFeedback invalid className={!!errors.content3 && touched.content3 ? 'd-block' : 'd-none'}>
                      {errors.content3}
                    </CFormFeedback>
                  </CCol>


                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Nội dung chuyển khoản</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="content3"
                      autoComplete="off"
                      value={values.content3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.content3 && touched.content3}
                    />
                    <CFormFeedback invalid className={!!errors.content3 && touched.content3 ? 'd-block' : 'd-none'}>
                      {errors.content3}
                    </CFormFeedback>
                  </CCol> */}

                </CRow>
                <CButton className="btn-custom minw-120 primary-500 mt-4" type="submit">Lưu thay đổi</CButton>
            </>
          </CForm>
        )}
      </Formik>
        </div>
      </div>
    </>
  );
};

export default ConfigsManagement;
