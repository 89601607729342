import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { INewUser } from '../../../shared/model/user.model';
import { IParams } from '../../../shared/shared-interfaces';
import { IConfigs, INewConfigs } from '@/shared/model/configs.models';

const prefix = 'configs';

export interface IConfigsParams extends IParams {
  // status?: ResponseStatus;
  // flightId?: number;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IConfigsParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IConfigs[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: number, thunkAPI) => {
  try {
    const { data } = await axios.get<IConfigs>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewConfigs, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IConfigs, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IConfigs>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: number, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IConfigsDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IConfigsDel, thunkAPI) => {
    try {
      await axios.post<IConfigs>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
