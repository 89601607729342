export enum ErrorMessage {
  PROJECT_DOES_NOT_EXIST = 'PROJECT_DOES_NOT_EXIST',
  VIDEO_DOES_NOT_EXIST = 'VIDEO_DOES_NOT_EXIST',
  LAYER_DOES_NOT_EXIST = 'LAYER_DOES_NOT_EXIST',
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
  USER_OR_PASSWORD_INVALID = 'USER_OR_PASSWORD_INVALID',
  INVALID_OLD_PASSWORD = 'INVALID_OLD_PASSWORD',
  CONFIRM_PASSWORD_INVALID = 'CONFIRM_PASSWORD_INVALID',
  USER_NAME_EXIST = 'USER_NAME_EXIST',
  EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
  PERMISSION_GROUP_DOES_NOTE_EXIST = 'PERMISSION_GROUP_DOES_NOTE_EXIST',
  SERVER_CANNOT_SEND_MAIL = 'SERVER_CANNOT_SEND_MAIL',
  NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL = 'NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL',
  PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS',
  UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS',
  DATA_TYPE_ERROR = 'DATA_TYPE_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  ROOM_CHAT_NOT_EXIST = 'ROOM_CHAT_NOT_EXIST',
  USER_NOT_IN_PROJECT = 'USER_NOT_IN_PROJECT',
  CANNOT_CREATE_DISCUSSION = 'CANNOT_CREATE_DISCUSSION',
  USER_NOT_ACTIVE = 'USER_NOT_ACTIVE',
  Unauthorized = 'Unauthorized'
}

export const mapErrorMessage = {
  [ErrorMessage.PROJECT_DOES_NOT_EXIST]: 'Project không tồn tại',
  [ErrorMessage.VIDEO_DOES_NOT_EXIST]: 'Video không tồn tại',
  [ErrorMessage.LAYER_DOES_NOT_EXIST]: 'Layer không tồn tại',
  [ErrorMessage.USER_DOES_NOT_EXIST]: 'Số điện thoại không tồn tại',
  [ErrorMessage.USER_OR_PASSWORD_INVALID]: 'Số điện thoại không đúng',
  [ErrorMessage.INVALID_OLD_PASSWORD]: 'Mật khẩu cũ không đúng',
  [ErrorMessage.CONFIRM_PASSWORD_INVALID]: 'Mật khẩu xác nhận không khớp',
  [ErrorMessage.USER_NAME_EXIST]: 'Số điện thoại đã tồn tại',
  [ErrorMessage.EMAIL_ALREADY_EXIST]: 'Email đã tồn tại',
  [ErrorMessage.PERMISSION_GROUP_DOES_NOTE_EXIST]: 'Nhóm quyền không tồn tại',
  [ErrorMessage.SERVER_CANNOT_SEND_MAIL]: 'Lỗi không thể gửi email',
  [ErrorMessage.NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL]: 'Mật khẩu mới đã được gửi đến mail của bạn',
  [ErrorMessage.PASSWORD_CHANGE_SUCCESS]: 'Thay đổi mật khẩu thành công',
  [ErrorMessage.UPDATE_INFO_SUCCESS]: 'Thay đổi thông tin cá nhân thành công',
  [ErrorMessage.DATA_TYPE_ERROR]: 'Dữ liệu truyền lên không đúng định dạng',
  [ErrorMessage.INTERNAL_SERVER_ERROR]: 'Lỗi iem',
  [ErrorMessage.FORBIDDEN]: 'Không có quyền truy cập',
  [ErrorMessage.ROOM_CHAT_NOT_EXIST]: 'Phòng chat không tồn tại',
  [ErrorMessage.USER_NOT_IN_PROJECT]: 'Người dùng không trong dự án',
  [ErrorMessage.CANNOT_CREATE_DISCUSSION]: 'Không thể tạo thảo luận',
  [ErrorMessage.Unauthorized]: 'Vui lòng đăng nhập',
  [ErrorMessage.USER_NOT_ACTIVE]: 'Tài khoản của bạn đã bị khóa',
};
