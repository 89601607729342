import { Link } from "react-router-dom"
import logo from '../../../../assets/img/web/logo.png'
import { useRouter } from "@/shared/utils/hooks/useRouter"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/reducers"
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react-pro"
import { useState } from "react"
import { AppDispatch } from "@/store"
import { logout } from "../../auth/auth.reducer"
const Header = () => {
    const {navigate } = useRouter()
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: RootState) => state.authentication)
    const menu = [
        { name: 'Vé máy bay', to: '/' },
        { name: 'Giới thiệu', to: '/gioi-thieu' },
        { name: 'Kiểm tra đơn hàng', to: '/order-check' },
        { name: 'Liên hệ', to: '/contact' },
    ]
    const [showModal, setShowModal] = useState(false)
    return <>
        <div className="header-custom d-flex align-items-center">

            <div className="container-custom">

                <div className="d-flex justify-content-between w-100 align-items-center">

                    <div className="logo py-1">
                        <img className="h-100" src={logo} alt="" onClick={() => { window.location.href = '/' }} />
                    </div>

                    <div className="menu gap-4 d-none d-lg-flex align-items-center">
                        {
                            menu.map((item, index) => <div className="item" key={index}>
                                {user ? (item.name == 'Vé máy bay' ? 
                                <a className="text-uppercase text-decoration-none text-black fw-bold" href={item.to} style={{ fontSize: 17 }}>{item.name}</a> : <Link className="text-uppercase text-decoration-none text-black fw-bold" to={item.to} style={{ fontSize: 17 }}>{item.name}</Link>) :
                                 <Link to={'/register'} className="text-uppercase text-decoration-none text-black fw-bold" style={{ fontSize: 17, cursor: 'pointer' }}>{item.name}</Link>}

                                {/* {item.name == 'Vé máy bay' ? <a className="text-uppercase text-decoration-none text-black fw-bold" href={item.to} style={{ fontSize: 17 }}>{item.name}</a> : <Link className="text-uppercase text-decoration-none text-black fw-bold" to={item.to} style={{ fontSize: 17 }}>{item.name}</Link>} */}
                                {/* <Link className="text-uppercase text-decoration-none text-black fw-bold" to={item.to} style={{ fontSize: 17 }}>{item.name}</Link> */}
                            </div>)
                        }
                    </div>

                    <div className="logo d-flex align-items-center gap-2">
                        {/* <svg width="44" height="44" viewBox="0 0 22 22" fill="none"><path d="M4.54906 15.408V12.9747C4.54906 12.1663 5.1824 11.4413 6.0824 11.4413C6.89073 11.4413 7.61573 12.0747 7.61573 12.9747V15.3163C7.61573 16.9413 6.26573 18.2913 4.64073 18.2913C3.01573 18.2913 1.66573 16.933 1.66573 15.3163V10.183C1.57406 5.49967 5.27406 1.70801 9.9574 1.70801C14.6407 1.70801 18.3324 5.49967 18.3324 10.0913V15.2247C18.3324 16.8497 16.9824 18.1997 15.3574 18.1997C13.7324 18.1997 12.3824 16.8497 12.3824 15.2247V12.883C12.3824 12.0747 13.0157 11.3497 13.9157 11.3497C14.7241 11.3497 15.4491 11.983 15.4491 12.883V15.408" stroke="#e03e2d" stroke-linecap="round" stroke-linejoin="round"></path></svg>                            
                        <div>
                            <div>
                                <div style={{ fontSize: 13, color: "#333333", lineHeight: 1, marginBottom: 3 }}>Hỗ trợ 24/7</div>
                                <div className="fw-bold" style={{ fontSize: 18, color: "#e03e2d", lineHeight: 1 }}>1900 252591</div>
                            </div>
                        </div> */}
                        {
                            user ? <>
                            <div className="fw-bold d-flex align-items-center gap-2">
                                 <a id="user-logined" className="text-decoration-none text-black fw-bold align-items-center gap-2 d-flex" href="/lich-su-dat-ve" style={{ color: 'rgb(44, 172, 226)' }}>
                                    <i style={{ fontSize: 20, color: 'rgb(44, 172, 226)' }} className="fa-regular fa-user"></i>
                                    {user.username}
                                 </a>
                                 <div onClick={() => setShowModal(true)} className="fw-bold d-flex align-items-center gap-2 cursor-pointer"><i style={{ fontSize: 20, color: 'rgb(44, 172, 226)' }} className="fa-solid fa-arrow-right-from-bracket"></i></div>
                            </div>
                            </> : <div style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>
                            <i style={{ fontSize: 25, color: 'rgb(44, 172, 226)' }} className="fa-regular fa-user"></i>
                        </div>
                        }
                        
                                                
                    </div>
                    
                </div>

            </div>

            <CModal visible={showModal} onClose={() => setShowModal(false)} >
                <CModalHeader closeButton>
                    <CModalTitle>Thông báo</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Bạn có muốn đăng xuất không?</p>
                </CModalBody>
                <CModalFooter className="d-flex justify-content-center">
                    <CButton onClick={() => {
                        dispatch(logout())
                        setShowModal(false)
                    }}>Xác nhận</CButton>
                </CModalFooter>
            </CModal>

        </div>
    </>
}

export default Header