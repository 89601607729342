import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import Home from './Home';
import Search from './Search';
import GioiThieu from './GioiThieu';
import OrderCheck from './OrderCheck';
import Contact from './Contact';
import HuongDanThanhToan from './HuongDanThanhToan';
import { RequireAuth } from '../auth/RequireAuth';
import LichSuDat from './LichSuDat';

const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '/gioi-thieu',
    element: <RequireAuth><GioiThieu /></RequireAuth>,
  },
  {
    path: '/order-check',
    element: <RequireAuth><OrderCheck /></RequireAuth>,
  },
  {
    path: '/contact',
    element: <RequireAuth><Contact /></RequireAuth>,
  },
  {
    path: '/huong-dan-thanh-toan',
    element: <RequireAuth><HuongDanThanhToan /></RequireAuth>,
  },
  {
    path: '/lich-su-dat-ve',
    element: <RequireAuth><LichSuDat /></RequireAuth>,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const WebsManagementRoutes = () => useRoutes(WebManagementLayout);
export default WebsManagementRoutes;

