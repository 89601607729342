import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import TheLayout from './components/containers/TheLayout';
import BaseRoutes from './components/dummy/base/routes';
import ButtonRoutes from './components/dummy/buttons/routes';
import FormRoutes from './components/dummy/forms/routes';
import IconRoutes from './components/dummy/icons/routes';
import NotificationRoutes from './components/dummy/notifications/routes';
import ThemeRoutes from './components/dummy/theme/routes';
import AccountSettingRoutes from './components/modules/AccountSetting/routes';
import { RequireAuth } from './components/modules/auth/RequireAuth';
import ChatRoutes from './components/modules/Chat/routes';
import UsersManagementRoutes from './components/modules/UserManagement/routes';
import WebsManagementRoutes from './components/modules/Web/routes';
import { RequireAuthAdmin } from './components/modules/auth/RequireAuthAdmin';
import SanBayManagement from './components/modules/SanBayManagement/SanBayManagement';
import HangBayManagement from './components/modules/HangBayManagement/HangBayManagement';
import FlightManagement from './components/modules/FlightManagement/FlightManagement';
import ListVeManagement from './components/modules/FlightManagement/ListVeManagement';
import ConfigsManagement from './components/modules/ConfigsManagement/ConfigsManagement';
// import UsersManagementRoutes from './components/modules/SystemSetting/UserManagement/routes';

const TransactionManagement = React.lazy(() => import('./components/modules/TransactionManagement/TransactionManagement'));
const RechargeManagement = React.lazy(() => import('./components/modules/RechargeManagement/RechargeManagement'));
const HistoryManagement = React.lazy(() => import('./components/modules/HistoryManagement/HistoryManagement'));
const GameManagement = React.lazy(() => import('./components/modules/GameManagement/GameManagement'));

const Table = React.lazy(() => import('./components/dummy/Table'));
const ForgotPassword = React.lazy(() => import('./components/modules/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/modules/auth/ResetPassword'));
const Login = React.lazy(() => import('./components/modules/auth/Login'));
const LoginAdmin = React.lazy(() => import('./components/modules/auth/LoginAdmin'));
const Register = React.lazy(() => import('./components/modules/auth/Register'));
const ResetSuccess = React.lazy(() => import('./components/modules/auth/ResetSuccess'));

const Dashboard = React.lazy(() => import('./components/dummy/dashboard/Dashboard'));

const Charts = React.lazy(() => import('./components/dummy/charts/Charts'));
const Widgets = React.lazy(() => import('./components/dummy/widgets/Widgets'));
// Error pages

const Page404 = React.lazy(() => import('./components/modules/Page404'));
const Page500 = React.lazy(() => import('./components/modules/Page500'));
const Page403 = React.lazy(() => import('./components/modules/Page403'));

const DummyBackOfficeHome = () => <h4 style={{ padding: '20px' }}>Xin chào, hệ thống quản lý</h4>;

const settingRoutes: RouteObject[] = [
  // { path: '', element: <SettingManagement /> },
  // { path: 'users/*', element: <UsersManagementRoutes /> },
];

const privateRoutes: RouteObject[] = [
  { path: '', element: <DummyBackOfficeHome /> },
  { path: 'account/*', element: <AccountSettingRoutes /> },
  { path: 'table', element: <Table /> },
  { path: 'dashboard', element: <Dashboard /> },
  { path: 'theme/*', element: <ThemeRoutes /> },
  { path: 'base/*', element: <BaseRoutes /> },
  { path: 'buttons/*', element: <ButtonRoutes /> },
  { path: 'charts', element: <Charts /> },
  { path: 'forms/*', element: <FormRoutes /> },
  { path: 'icons/*', element: <IconRoutes /> },
  { path: 'notifications/*', element: <NotificationRoutes /> },
  { path: 'widgets', element: <Widgets /> },
  { path: 'users/*', element: <UsersManagementRoutes /> },
  { path: 'sanbay', element: <SanBayManagement /> },
  { path: 'config', element: <ConfigsManagement /> },
  { path: 'hangbay', element: <HangBayManagement /> },
  { path: 'flight', element: <FlightManagement /> },
  { path: 'flight/:id/ve', element: <ListVeManagement /> },
  { path: 'games', element: <GameManagement /> },
  { path: 'history', element: <HistoryManagement /> },
  { path: 'transaction', element: <TransactionManagement /> },
  { path: 'recharge', element: <RechargeManagement /> },
  {
    path: 'settings/*',
    children: settingRoutes,
    caseSensitive: true,
  },
  { path: 'message/*', element: <ChatRoutes /> },
  {
    path: 'users/*',
    children: settingRoutes,
    caseSensitive: true,
  },
  // { path: '*', element: <Navigate to="/404" replace /> },
];

const publicRoutes: RouteObject[] = [
  {
    path: '/*', element:
      (
          <WebsManagementRoutes />
      )
  },
  {
    path: '/admin/*',
    element: (
      <RequireAuthAdmin>
        <TheLayout />
      </RequireAuthAdmin>
    ),
    children: privateRoutes,
    caseSensitive: true,
  },
  { path: '/404', element: <Page404 /> },
  { path: '/500', element: <Page500 /> },
  { path: '/403', element: <Page403 /> },
  { path: '/admin/login', element: <LoginAdmin /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot', element: <ForgotPassword /> },
  { path: '/reset', element: <ResetPassword /> },
  { path: '/reset-success', element: <ResetSuccess /> },
  // { path: '/auth/*', element: <AuthRoutes /> },
  { path: '*', element: <Navigate to="/404" replace /> },
];

const RouteRender = () => useRoutes(publicRoutes);

// const BackOfficeRender = () => useRoutes(backOffice);

// const BackOfficeRender = () => {
//   const { location } = useRouter();

//   const { user } = useSelector((state: RootState) => state.authentication);

//   const routes = useRoutes(
//     Boolean(user)
//       ? backOffice
//       : [{ path: '*', element: <Navigate to="/auth/login" state={{ path: location.pathname }} /> }]
//   );
//   return routes;
// };

export { RouteRender, privateRoutes, publicRoutes };
